import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const FencingProductsPage = () => {
  return (
    <Layout hero={<HeroImage title="Fencing Products" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center mt-4">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  Orrcon Steel has all your fencing needs covered, including the right tools to create a high quality fence, gate or enclosure. 
                  We offer a wide range of fencing accessories to complement our steel building products. 
                  This includes general purpose brackets and base plates, safety accessories, 
                  load restraints and tools ranging from bolt cutters to picket drivers.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/fencing-products">
                    <h2 className="title">FENCING PRODUCTS</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/fence-panels" className="mb-2">
                        Fence Panels
                      </Link>
                      <p>Manufactured from Australian, long life pre-painted COLORBOND® and extensively tested in harsh Australian weather conditions.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/fencing-extras" className="mb-2">
                        Fencing Extras
                      </Link>
                      <p>Whatever fence project needs attention, all your fencing accessories can be found at Orrcon Steel.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/fencing-joints" className="mb-2">
                        Fencing Joints
                      </Link>
                      <p>If you're looking to build a galvanised tube fence, you're going to need galvanised fencing joints on all posts.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/gate-packs" className="mb-2">
                        Gate Packs
                      </Link>
                      <p>Orrcon Steel is a leading supplier of fence and gate fittings and has a comprehensive range of gate packs to suit most fence designs.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/handrail-systems" className="mb-2">
                        Handrail Systems
                      </Link>
                      <p>Orrcon Steel offers a comprehensive range of profiled handrail system products, these fabricated handrail components include stanchions, bends.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/mesh-wires" className="mb-2">
                        Mesh & Wires
                      </Link>
                      <p>Orrcon Steel's extensive wide range of fencing wire & mesh are made to meet Australian Standards, with quality testing to ensure trouble free.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/post-caps" className="mb-2">
                        Post Caps
                      </Link>
                      <p>Orrcon Steel supplies a range of post caps and finials in many sizes and styles.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/rural-fencing-gates" className="mb-2">
                        Rural Fencing & Gates
                      </Link>
                      <p>Orrcon Steel stocks a range of fence posts and gates for rural farming applications.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/tools" className="mb-2">
                        Tools
                      </Link>
                      <p>Orrcon Steel supplies a range of quality building and fencing tools to make building and maintaining your fence as easy as possible.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default FencingProductsPage

export const Head = () => <title>Fencing Products</title>
